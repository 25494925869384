
import { Options, Vue } from 'vue-class-component'
import AdminEvents from '@/components/Events/AdminEvents/index.vue' // @ is an alias to /src

@Options({
  components: {
    AdminEvents
  }
})
export default class EventsView extends Vue {}

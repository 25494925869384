import { Options, Vue } from 'vue-class-component'
import { Component, Watch } from 'vue-property-decorator'
// import BaseFunctions from '@/components/Utility/Base'
import CommonFunctions from '@/components/Utility/Common'
import { Events } from '@/services/EventsDataService'
import MembersDataService from '@/services/MembersDataService'
import ClubsDataService from '@/services/ClubsDataService'
import { Products } from '@/services/ProductsDataService'
import MediasDataService from '@/services/MediasDataService'
import SysEvent, { SysEventEventType, SysEventClubId, SysEventRegistration } from '@/types/SysEvent'
import SysMember from '@/types/SysMember'
import SysClub from '@/types/SysClub'
import SysProduct, { SysProductProductType } from '@/types/SysProduct'
import exportFromJSON from 'export-from-json'

/* export default defineComponent({
  name: 'AdminEvents',
  setup () {
    // component setup
  }
}) */

type eventDataType = { eventInvitation: any[]; eventTitle: string; eventDescription: string; eventLocation: string; eventPrice: string; eventType: number; eventStart: string; eventEnd: string; clubEmail: string; clubTelefon: string; clubName: string, extraContactTelephone: string; extraContactEmail: string; registrationDeadline: string; }
type eventTypeDataType = { eventTypeTitel: string; eventTypeDescription: string }
type dataReturnType = { events: any; eventTypeTitleOptions: any[]; klubbers: any; medias: any; filterStatusStringValue: string; selectedEventInvitation: any; memberSearchInputString: string; totalPages: number; tabValue: string; index: number; error: any; }

@Options({
  props: {
    msg: String
  }
})
export default class AdminEvents extends Vue {
  msg!: string
  error: any = null
  private tabValue = 'Vis og rediger arrangementer'
  private events: SysEvent[] = []
  private eventtypes: SysEventEventType[] = []
  private tempEvent = {} as SysEvent
  private tempEventType = {} as SysEventEventType
  private klubbers: SysClub[] = []
  private medias: any[] = []
  eventData: eventDataType = { eventInvitation: [], eventTitle: '', eventDescription: '', eventLocation: '', eventPrice: '0', eventType: 0, eventStart: '', eventEnd: '', clubEmail: '', clubTelefon: '', clubName: '', extraContactTelephone: '', extraContactEmail: '', registrationDeadline: '' }
  eventTypeData: eventTypeDataType = { eventTypeTitel: '', eventTypeDescription: '' }
  filterStatusStringValue = 'Vis alt'
  filterStatusStringOptions: string[] = ['Vis alt', 'Udgivet', 'Kladde']
  productValue = { name: 'Vælg produkt', value: 0, typeId: 0 }
  productOptions: { name: string, value: number, typeId: number }[] = []
  productTypeValue = { name: 'Vælg produktgruppe', value: 0 }
  productTypeOptions: { name: string, value: number }[] = []
  eventTypeTitleValue = { text: 'Vælg her', value: '0' }
  eventTypeTitleOptions: { text: string; value: string }[] = [{ text: 'Vælg her', value: '0' }]
  eventClubsNameValue = { name: '', value: '' }
  eventClubsNameOptions: { name: string; value: string }[] = []
  eventSearchInputValue = ''
  eventSearchTerm = ''
  toggleIconsSortingValue = 0
  private oldSort = ''
  private currentListSortingOrder = ''
  private submitted = false
  private submitted2 = false
  private editEventModal = false
  private editEventTypeModal = false
  private addInvitationModal = false
  private addInvitationToNewEventModal = false
  private deleteEventWarningModal = false
  private deleteEventTypeWarningModal = false
  private deleteEventTypeId = 0
  private deleteEventId = 0
  private lastEditedEventId = 0
  private lastEditedEventTypeId = 0
  private lastCreatedEventId = 0
  private lastCreatedEventTypeId = 0
  private index = 0
  private currentPage = 1
  private totalPages = 0
  private totalNumberOfPages = 0
  pageSizeValue = 25
  pageSizeValueString = '25'
  pageSizeOptions: string[] = ['10', '25', '100']
  selectedEventInvitation = ''
  // Variables for handlingen event registrations.
  private selectedFile: any = null
  private eventRegistration: SysEventRegistration[] = []
  private eventMemberInfo: SysMember[] = []
  private eventRegistrationDrawer = false
  private eventRegistrationTitle = ''
  private eventMemberId = 0
  private eventRegistrationId = 0
  private removeMemberWarningModal = false
  private currentEventRegistrationId = 0
  private addMemberToEventModal = false
  private findMemberValue = { id: 0, name: 'Vælg medlem' }
  private findMemberOptions: { id: number, name: string }[] = [{ id: 0, name: 'Vælg medlem' }]
  private memberSearchInputString = ''
  private memberSearchTerm = ''
  private backSpace = ''
  private tempMembers: SysMember[] = []

  readonly name : string = 'AdminEvents'
  $Message: any

  data (): dataReturnType {
    return {
      events: this.events,
      eventTypeTitleOptions: this.eventTypeTitleOptions,
      klubbers: this.klubbers,
      medias: this.medias,
      filterStatusStringValue: this.filterStatusStringValue,
      selectedEventInvitation: '',
      memberSearchInputString: this.memberSearchInputString,
      totalPages: this.totalPages,
      tabValue: 'Vis og rediger arrangementer',
      index: this.index,
      error: this.error
    }
  }

  @Watch('deleteEventWarningModal')
  onEventWarningModalChange (toggleValue: boolean) : void {
    if (toggleValue === false) {
      this.deleteEventId = 0
    }
  }

  @Watch('deleteEventWarningModal')
  onRegistrationWaringModalChange (toggleValue: boolean) : void {
    if (!toggleValue) {
      this.eventMemberId = 0
      this.eventRegistrationId = 0
    }
  }

  @Watch('deleteEventTypeWarningModal')
  onEventTypeWarningModalChange (toggleValue: boolean) : void {
    if (toggleValue === false) {
      this.deleteEventTypeId = 0
    }
  }

  @Watch('filterStatusStringValue')
  onEventStatusChanged (statusValue: string, oldStatusValue: string) : void {
    console.log('Filter status changed to: ' + statusValue)

    this.retrieveEvents()
  }

  @Watch('pageSizeValueString')
  onPageSizeValueChange (newVal: any) : void {
    this.pageSizeValue = Number(newVal)
    this.currentPage = 1
    this.retrieveEvents()
  }

  @Watch('backSpace', { deep: true })
  onBackSpacePress () : void {
    if (this.backSpace === 'Backspace' && this.memberSearchInputString.length !== 0) {
      this.memberSearchInputString = this.memberSearchInputString.slice(0, -1)
      this.backSpace = ''
    }
  }

  @Watch('memberSearchInputString', { deep: true })
  watchInputString () : void {
    console.log(this.memberSearchInputString)

    if (this.memberSearchInputString.length > 3) {
      this.memberSearchTerm = this.memberSearchInputString
      this.findMembers()
    }
  }

  @Watch('productValue')
  onEventProductValueChanged (productValue: { name: string, value: number, typeId: number }, oldProductValue: { name: string, value: number, typeId: number }) : void {
    // console.log('Product value changed to: ' + productValue.value)

    // Set the product type value to the one, that is matching the product, if a product has been selected and a matching product type has been found.
    if (productValue.value >= 1 && productValue.typeId >= 1) {
      const prodTypeOptIndex = this.productTypeOptions.findIndex(x => x.value === productValue.typeId)

      if (prodTypeOptIndex >= 0) {
        this.productTypeValue = { name: this.productTypeOptions[prodTypeOptIndex].name, value: productValue.typeId }
      }
    }
  }

  public exportRegistrationToExcel () : void {
    if (this.eventRegistration.length === 0) {
      return this.$Message.warning({ text: 'Der er ingen tilmeldte som kan eksporteres' })
    }

    const data: {Navn: string, Licens: string, Klub: string, Email: string, Telefon: string}[] = []
    for (const item of this.eventMemberInfo) {
      data.push({
        Navn: item.user_id.firstname + ' ' + item.user_id.lastname,
        Licens: (item.medlem_licens === null ? 'Ingen licens' : item.medlem_licens.toString()),
        Klub: (item.klubber_id === null ? 'Ingen klub' : item.klubber_id.klubber_klubnavn),
        Email: item.user_id.email,
        Telefon: item.user_id.phone
      })
    }

    const fileName = 'tilmeldinger_' + this.eventRegistrationTitle
    const exportType = exportFromJSON.types.xls

    exportFromJSON({ data, fileName, exportType })
  }

  public addMemberToEvent () : void {
    // If no member have been selected, bail out with at proper message to the end user.
    if (this.findMemberValue.id === 0) {
      return this.$Message.warning({ text: 'Vælg et medlem' })
    }

    // Also, check if the member is already signed up, and if so bail out with at proper message to the end user.
    const alreadySignedUp = this.eventRegistration.find(element => element.medlem_id.id === this.findMemberValue.id)
    if (alreadySignedUp !== undefined) {
      return this.$Message.warning({ text: 'Medlemmet er allerede tilmeldt' })
    }

    const createRegistration = {
      medlem_id: this.findMemberValue.id,
      event_id: this.currentEventRegistrationId
    }

    Events.EventRegistrationDataService.create(createRegistration)
      .then((response) => {
        console.log(response.data)

        this.findMemberValue = { id: 0, name: 'Vælg medlem' }
        this.retrieveEventRegistrations(this.currentEventRegistrationId, this.eventRegistrationTitle)
      })
      .catch((err) => {
        this.error = err
      })
  }

  public clearInput () : void {
    this.memberSearchInputString = ''
  }

  public findMembers () : void {
    this.findMemberOptions = [{ id: 0, name: 'Vælg medlem' }]

    MembersDataService.findByNameAndLicenseSearchTerm(this.memberSearchTerm)
      .then((response) => {
        this.tempMembers = response.data

        for (const member of this.tempMembers) {
          this.findMemberOptions.push({
            id: Number(member.id),
            name: member.user_id.firstname + ' ' + member.user_id.lastname + ' [' + (member.medlem_licens === null ? 'Ingen' : member.medlem_licens) + '] '
          })
        }

        console.log('FindMemberOptions: ' + JSON.stringify(this.findMemberOptions))
      })
      .catch((err) => {
        this.error = err
      })
  }

  public retrieveEventRegistrations (eventId: number, eventTitle: string) : void {
    this.eventRegistrationTitle = eventTitle
    this.currentEventRegistrationId = eventId

    Events.EventRegistrationDataService.getAll('', null, `event_id=${eventId.toString()}`)
      .then((response) => {
        this.eventRegistration = response.data

        if (this.eventRegistration.length > 0) {
          let memberParameter = ''

          for (const item of this.eventRegistration) {
            memberParameter += 'id=' + item.medlem_id.id + '&'
          }
          memberParameter = memberParameter.slice(0, -1)

          MembersDataService.getAll('', null, memberParameter)
            .then((response) => {
              this.eventMemberInfo = response.data
            })
            .catch((err) => {
              this.error = err
            })
        }

        this.eventRegistrationDrawer = true
      })
      .catch((err) => {
        this.error = err
      })
  }

  public getClubNameFromId (memberId: number) : string {
    let retVal = '????'
    const memberData = this.eventMemberInfo.find(element => element.id === memberId)

    if (memberData !== undefined) {
      retVal = memberData.klubber_id.klubber_klubnavn
    }

    return retVal
  }

  public getMemberNamesFromId (id: number) : string {
    let retVal = '????'
    const memberName = this.eventMemberInfo.find(element => element.id === id)

    if (memberName !== undefined) {
      retVal = memberName.user_id.firstname + ' ' + memberName.user_id.lastname
    }

    return retVal
  }

  public removeMemberWarning (eventRegistrationId: number, memberId: number) : void {
    this.eventRegistrationId = eventRegistrationId
    this.eventMemberId = memberId
    this.removeMemberWarningModal = true
  }

  public deleteEventRegistration (eventRegistrationId: number) : void {
    Events.EventRegistrationDataService.delete(eventRegistrationId.toString())
      .then((response) => {
        this.removeMemberWarningModal = false
        this.retrieveEventRegistrations(this.currentEventRegistrationId, this.eventRegistrationTitle)
      })
      .catch((err) => {
        this.error = err
      })
  }

  public turnToNewPage (pageChange : number) : void {
    this.currentPage += pageChange

    if (this.currentPage < 1) {
      this.currentPage = 1
    } else if (this.currentPage > this.totalNumberOfPages) {
      this.currentPage = this.totalNumberOfPages
    }

    this.retrieveEvents()
  }

  public jumpToPage (pageNumber : number) : void {
    this.currentPage = pageNumber

    if (this.currentPage < 1) {
      this.currentPage = 1
    } else if (this.currentPage > this.totalNumberOfPages) {
      this.currentPage = this.totalNumberOfPages
    }

    this.retrieveEvents()
  }

  // @Watch('toggleIconsSortingValue')
  // onListSortToggleChanged (toggleValue: string, oldToggleValue: string) : void {
  //   console.log('List sort toggle changed to: ' + toggleValue)
  //   if (toggleValue === 'check_box_outline_blank') {
  //     this.currentListSortingOrder = ''
  //   } else if (toggleValue === 'badge') {
  //     this.currentListSortingOrder = 'event_titel:asc,event_type_id.event_type_titel:asc'
  //   } else if (toggleValue === 'contact_mail') {
  //     this.currentListSortingOrder = 'event_start:asc'
  //   } else if (toggleValue === 'login') {
  //     this.currentListSortingOrder = 'event_sted:asc'
  //   } else if (toggleValue === 'how_to_reg') {
  //     this.currentListSortingOrder = 'updated_at:desc'
  //   }

  //   this.retrieveEvents()
  // }

  public columnSort (sortBy : string) : void {
    if (sortBy === 'reset') {
      this.currentListSortingOrder = ''
      this.oldSort = ''
      this.eventSearchInputValue = ''
      this.eventSearchTerm = ''
    }
    if (sortBy === 'titel') {
      if (this.oldSort === '' || this.oldSort !== sortBy) {
        this.currentListSortingOrder = 'event_titel:asc,event_type_id.event_type_titel:asc'
        this.oldSort = sortBy
      } else {
        this.currentListSortingOrder = 'event_titel:desc,event_type_id.event_type_titel:desc'
        this.oldSort = ''
      }
    }
    if (sortBy === 'pris') {
      if (this.oldSort === '' || this.oldSort !== sortBy) {
        this.currentListSortingOrder = 'event_pris:asc'
        this.oldSort = sortBy
      } else {
        this.currentListSortingOrder = 'event_pris:desc'
        this.oldSort = ''
      }
    }
    if (sortBy === 'start') {
      if (this.oldSort === '' || this.oldSort !== sortBy) {
        this.currentListSortingOrder = 'event_start:desc'
        this.oldSort = sortBy
      } else {
        this.currentListSortingOrder = 'event_start:asc'
        this.oldSort = ''
      }
    }
    if (sortBy === 'slut') {
      if (this.oldSort === '' || this.oldSort !== sortBy) {
        this.currentListSortingOrder = 'event_slut:desc'
        this.oldSort = sortBy
      } else {
        this.currentListSortingOrder = 'event_slut:asc'
        this.oldSort = ''
      }
    }
    if (sortBy === 'type') {
      if (this.oldSort === '' || this.oldSort !== sortBy) {
        this.currentListSortingOrder = 'event_type_id.event_type_titel:asc'
        this.oldSort = sortBy
      } else {
        this.currentListSortingOrder = 'event_type_id.event_type_titel:desc'
        this.oldSort = ''
      }
    }
    if (sortBy === 'sted') {
      if (this.oldSort === '' || this.oldSort !== sortBy) {
        this.currentListSortingOrder = 'event_sted:asc'
        this.oldSort = sortBy
      } else {
        this.currentListSortingOrder = 'event_sted:desc'
        this.oldSort = ''
      }
    }
    if (sortBy === 'klub') {
      if (this.oldSort === '' || this.oldSort !== sortBy) {
        this.currentListSortingOrder = 'klubber_id.klubber_klubnavn:asc'
        this.oldSort = sortBy
      } else {
        this.currentListSortingOrder = 'klubber_id.klubber_klubnavn:desc'
        this.oldSort = ''
      }
    }

    this.retrieveEvents()
  }

  @Watch('editEventModal')
  onEditEventModalToggleChanged (toggleValue: boolean, oldToggleValue: boolean) : void {
    if (toggleValue === false) {
      this.eventData = { eventInvitation: [], eventTitle: '', eventDescription: '', eventLocation: '', eventPrice: '0', eventType: 0, eventStart: '', eventEnd: '', clubEmail: '', clubTelefon: '', clubName: '', extraContactEmail: '', extraContactTelephone: '', registrationDeadline: '' }
      this.eventTypeTitleValue = { text: 'Vælg her', value: '0' }
    }
  }

  @Watch('editEventTypeModal')
  onEditEventTypeModalToggleChanged (toggleValue: boolean, oldToggleValue: boolean) : void {
    if (toggleValue === false) {
      this.eventTypeData = { eventTypeTitel: '', eventTypeDescription: '' }
    }
  }

  public forceRerenderEventsList () : void {
    this.error = 'Opdaterer ...'
    this.$nextTick(() => {
      this.error = null
    })
  }

  public locConvertFromUTCtoLocalDateTime (inputDatetimeString: string) : string {
    let retVal = ''
    retVal = CommonFunctions.convertFromUTCtoLocalDateTime(inputDatetimeString, 0)
    const strPos = retVal.indexOf(' ')
    retVal = (retVal.substring(0, strPos) + 'T' + retVal.substring(strPos + 1)).substring(0, 19)

    return retVal
  }

  public findEvent () : void {
    console.log('Search event.')

    this.eventSearchTerm = this.eventSearchInputValue
    this.retrieveEvents()
  }

  public editEvent (eventId : number) : void {
    console.log('Edit event item with Id: ' + eventId)
    this.lastEditedEventId = eventId

    Events.EventsDataService.get(eventId.toString())
      .then((response) => {
        this.tempEvent = response.data
        console.log(response.data)
        // console.log(this.tempEvent.produkt_id)

        this.eventData = {
          eventTitle: this.tempEvent.event_titel,
          eventDescription: this.tempEvent.event_beskrivelse,
          eventLocation: this.tempEvent.event_sted,
          eventPrice: this.tempEvent.event_pris.toString(),
          eventType: Number(this.tempEvent.event_type_id.id),
          eventStart: this.locConvertFromUTCtoLocalDateTime(this.tempEvent.event_start).substring(0, 16),
          eventEnd: this.locConvertFromUTCtoLocalDateTime(this.tempEvent.event_slut).substring(0, 16),
          clubEmail: this.tempEvent.klubber_id === null ? '' : this.tempEvent.klubber_id.klubber_email,
          clubTelefon: this.tempEvent.klubber_id === null ? '' : this.tempEvent.klubber_id.klubber_telefonnummer,
          clubName: this.tempEvent.klubber_id === null ? '' : this.tempEvent.klubber_id.klubber_klubnavn,
          eventInvitation: this.tempEvent.event_invitation,
          extraContactEmail: this.tempEvent.event_tilmelding_mail,
          // extraContactEmail: this.tempEvent.event_tilmelding_mail === null ? '' : this.tempEvent.event_tilmelding_mail,
          extraContactTelephone: this.tempEvent.event_kontakt_telefon,
          // extraContactTelephone: this.tempEvent.event_kontakt_telefon === null ? '' : this.tempEvent.event_kontakt_telefon,
          registrationDeadline: (this.tempEvent.event_sidstetilmelding === null ? this.tempEvent.event_start.substring(0, 10) : this.tempEvent.event_sidstetilmelding)
        }

        if (this.tempEvent.event_type_id === undefined || this.tempEvent.event_type_id.id === null) {
          this.eventTypeTitleValue = { text: 'Vælg her', value: '0' }
        } else {
          this.eventTypeTitleValue = { text: this.tempEvent.event_type_id.event_type_titel, value: (this.tempEvent.event_type_id.id === undefined ? '0' : Number(this.tempEvent.event_type_id.id).toString()) }
        }
        if (this.tempEvent.klubber_id === undefined || this.tempEvent.klubber_id === null) {
          this.eventClubsNameValue = { name: 'Vælg her', value: '0' }
        } else {
          this.eventClubsNameValue = { name: this.tempEvent.klubber_id.klubber_klubnavn, value: Number(this.tempEvent.klubber_id.id).toString() }
        }

        if (this.tempEvent.produkt_type_id !== null) {
          this.productTypeValue = { name: this.tempEvent.produkt_type_id.produkt_type_navn, value: Number(this.tempEvent.produkt_type_id.id) }
        }

        Products.ProductsDataService.getAll('', null, 'produkt_status=true')
          .then((response2) => {
            const tempProduct = response2.data as SysProduct[]
            // console.log('[editEvent()] tempProduct = ' + JSON.stringify(tempProduct))

            for (const item of tempProduct) {
              this.productOptions.push({ name: item.produkt_navn, value: Number(item.id), typeId: Number(item.produkt_type_id.id) })
            }
            this.productOptions.sort((a, b) => a.name.localeCompare(b.name))
            this.productOptions.unshift({ name: 'Vælg produkt', value: 0, typeId: 0 })

            if (this.tempEvent.produkt_id !== null) {
              this.productValue = { name: this.tempEvent.produkt_id.produkt_navn, value: Number(this.tempEvent.produkt_id.id), typeId: Number(this.tempEvent.produkt_type_id.id) }
            }

            this.editEventModal = true
          })
          .catch((err) => {
            // this.error = err
            console.log(err)
            this.editEventModal = true
          })
      })
      .catch((err) => {
        this.error = err
      })
  }

  public cancelEventEdit () : void {
    console.log('Cancel event item edit.')

    this.eventData = { eventInvitation: [], eventTitle: '', eventDescription: '', eventLocation: '', eventPrice: '0', eventType: 0, eventStart: '', eventEnd: '', clubEmail: '', clubTelefon: '', clubName: '', extraContactEmail: '', extraContactTelephone: '', registrationDeadline: '' }
    this.eventTypeTitleValue = { text: 'Vælg her', value: '0' }
    this.eventClubsNameValue = { name: '', value: '' }
    this.productValue = { name: 'Vælg produkt', value: 0, typeId: 0 }
    this.productTypeValue = { name: 'Vælg produktgruppe', value: 0 }
  }

  public statusEvent (eventId : number) : void {
    console.log('Change status of event item with Id: ' + eventId)

    Events.EventsDataService.get(eventId.toString())
      .then((response) => {
        this.tempEvent = response.data
        console.log(response.data)

        const updateEventData = {
          event_status: !this.tempEvent.event_status
        }

        console.log('New status of event item = ' + updateEventData.event_status)

        Events.EventsDataService.update(eventId.toString(), updateEventData)
          .then((response) => {
            // this.lastUpdatedEventId = response.data.id
            console.log(response.data)

            const eventsIndexToUpdateStatus = this.events.findIndex(x => x.id === eventId)
            this.events[eventsIndexToUpdateStatus].event_status = updateEventData.event_status
          })
          .catch((err) => {
            this.error = err
            // console.log(err)
          })
      })
      .catch((err) => {
        this.error = err
      })
  }

  public deleteEventWarning (eventId: number) : void {
    this.deleteEventId = eventId
    this.deleteEventWarningModal = true
  }

  public deleteEvent (eventId : number) : void {
    console.log('Delete event item with Id: ' + eventId)

    Events.EventsDataService.delete(eventId.toString())
      .then((response) => {
        console.log(response.data)

        this.retrieveEvents()
        this.deleteEventWarningModal = false
      })
      .catch((err) => {
        this.error = err
        // console.log(err)
      })
  }

  public editEventType (eventTypeId: number) : void {
    console.log('Edit event type with Id: ' + eventTypeId)

    this.editEventTypeModal = true
    this.lastEditedEventTypeId = eventTypeId

    Events.EventTypesDataService.get(eventTypeId.toString())
      .then((response) => {
        this.tempEventType = response.data
        console.log(response.data)

        this.eventTypeData = { eventTypeTitel: this.tempEventType.event_type_titel, eventTypeDescription: this.tempEventType.event_type_beskrivelse }
      })
      .catch((err) => {
        this.error = err
      })
  }

  public cancelEventTypeEdit () : void {
    console.log('Cancel event type edit.')

    this.eventTypeData = { eventTypeTitel: '', eventTypeDescription: '' }
  }

  public statusEventType (eventTypeId: number) : void {
    console.log('Change status of event type with Id: ' + eventTypeId)

    Events.EventTypesDataService.get(eventTypeId.toString())
      .then((response) => {
        this.tempEventType = response.data
        console.log(response.data)

        const updateEventTypeData = {
          event_type_status: !this.tempEventType.event_type_status
        }

        console.log('New status of event type = ' + updateEventTypeData.event_type_status)

        Events.EventTypesDataService.update(eventTypeId.toString(), updateEventTypeData)
          .then((response) => {
            // this.lastUpdatedEventId = response.data.id
            console.log(response.data)

            const eventTypesIndexToUpdateStatus = this.eventtypes.findIndex(x => x.id === eventTypeId)
            this.eventtypes[eventTypesIndexToUpdateStatus].event_type_status = updateEventTypeData.event_type_status
          })
          .catch((err) => {
            this.error = err
            // console.log(err)
          })
      })
      .catch((err) => {
        this.error = err
      })
  }

  public deleteEventTypeWarning (eventTypeId: number) : void {
    this.deleteEventTypeId = eventTypeId
    this.deleteEventTypeWarningModal = true
  }

  public deleteEventType (eventTypeId: number) : void {
    let tempEvents: SysEvent[] = []

    if (eventTypeId !== 9) { // Prevent delete dummy eventtype
      console.log('Fetching all events with the with eventTypeId of ' + eventTypeId + ' and reassigning them to eventTypeId 9')

      Events.EventsDataService.getAll('', null, `event_type_id.id=${eventTypeId.toString()}`)
        .then((response) => {
          tempEvents = response.data

          if (tempEvents.length > 0) {
            for (const item of tempEvents) {
              const updateEventData = {
                event_type_id: 9
              }

              Events.EventsDataService.update(Number(item.id).toString(), updateEventData)
                .then((response) => {
                  console.log(response.data)
                })
                .catch((err) => {
                  this.error = err
                })
            }
          }
        })
        .catch((err) => {
          this.error = err
        })
        .then(() => {
          console.log('Delete event type with Id: ' + eventTypeId)

          Events.EventTypesDataService.delete(eventTypeId.toString())
            .then((response) => {
              console.log(response.data)

              this.retrieveEventTypes()
              this.retrieveEvents()
              this.deleteEventTypeWarningModal = false
            })
            .catch((err) => {
              this.error = err
              // console.log(err)
            })
        })
    }
  }

  public createEvent () : void {
    if (this.eventData.eventTitle.length > 1 && this.eventData.eventDescription.length > 1 &&
      !isNaN(Number(this.eventData.eventPrice)) && this.eventData.eventStart.length > 1 &&
      this.eventData.eventEnd.length > 1 && this.eventTypeTitleValue.value !== '0') {
      const todaysData = new Date().toISOString().split('T')[0]
      let productId = 0
      const promises = []
      const asyncCreateEventProduct = async (createProduct: any) => {
        await Products.ProductsDataService.create(createProduct)
          .then((response) => {
            console.log(response.data)

            productId = response.data.id
          })
          .catch((err) => {
            this.error = err
          })
      }

      if (Number(this.eventData.eventPrice) > 0 && this.eventTypeTitleValue.value !== '6') {
        const productData = {
          produkt_status: true,
          produkt_navn: this.eventData.eventTitle,
          produkt_pris: Number(this.eventData.eventPrice),
          produkt_datofra: todaysData,
          produkt_datotil: (new Date(this.eventData.eventStart)).toISOString(),
          produkt_beskrivelse: 'Tilmelding til ' + this.eventData.eventTitle,
          user_id: 3, /* Fixme: should be the logged in user */
          produkt_type_id: 12
        }
        promises.push(asyncCreateEventProduct(productData))
      }
      Promise.all(promises)
        .then(() => {
          const createEventData = {
            event_titel: this.eventData.eventTitle,
            event_beskrivelse: this.eventData.eventDescription,
            event_pris: this.eventData.eventPrice,
            event_start: (new Date(this.eventData.eventStart)).toISOString(),
            event_slut: (new Date(this.eventData.eventEnd)).toISOString(),
            event_sted: this.eventData.eventLocation,
            event_type_id: this.eventTypeTitleValue.value,
            event_status: true,
            klubber_id: this.eventClubsNameValue.value,
            user_id: 2, /* Fixme: should be the logged in user */
            event_invitation: (this.eventData.eventInvitation.length === 0 ? null : this.eventData.eventInvitation),
            event_tilmelding_mail: this.eventData.extraContactEmail,
            event_kontakt_telefon: this.eventData.extraContactTelephone,
            produkt_id: (productId === 0 ? null : productId),
            event_sidstetilmelding: (this.eventData.registrationDeadline === '' ? (new Date(this.eventData.eventStart)).toISOString() : this.eventData.registrationDeadline)
          }

          console.log('Attempt to create new event item with Id: ' + (1 + this.lastCreatedEventId))

          Events.EventsDataService.create(createEventData)
            .then((response) => {
              this.lastCreatedEventId = response.data.id
              console.log(response.data)

              this.submitted = true
              this.$Message.success({ text: 'Arrangementet er blevet oprettet' })
              this.newCreateEvent()
              this.tabValue = 'Vis og rediger arrangementer'
              // this.retrieveEvents()
            })
            .catch((err) => {
              this.error = err
              // console.log(err)
            })
        })
        .catch((err) => {
          this.error = err
        })

      // this.eventData = { eventInvitation: [], eventTitle: '', eventDescription: '', eventLocation: '', eventPrice: '0', eventType: 0, eventStart: '', eventEnd: '', clubEmail: '', clubTelefon: '', clubName: '', extraContactEmail: '', extraContactTelephone: '' }
      // this.eventTypeTitleValue = { text: 'Vælg her', value: '0' }
    }
  }

  public newCreateEvent () : void {
    this.submitted = false
    this.eventData = { eventInvitation: [], eventTitle: '', eventDescription: '', eventLocation: '', eventPrice: '0', eventType: 0, eventStart: '', eventEnd: '', clubEmail: '', clubTelefon: '', clubName: '', extraContactEmail: '', extraContactTelephone: '', registrationDeadline: '' }
    this.eventTypeTitleValue = { text: 'Vælg her', value: '0' }
    this.retrieveEvents()
  }

  public createEventType () : void {
    if (this.eventTypeData.eventTypeTitel.length > 1 && this.eventTypeData.eventTypeDescription.length > 1) {
      const createEventTypeData = {
        event_type_titel: this.eventTypeData.eventTypeTitel,
        event_type_beskrivelse: this.eventTypeData.eventTypeDescription,
        event_type_status: true
      }

      console.log('Attempt to create new event type with Id: ' + (1 + this.lastCreatedEventTypeId))

      Events.EventTypesDataService.create(createEventTypeData)
        .then((response) => {
          this.lastCreatedEventTypeId = response.data.id
          console.log(response.data)

          this.submitted2 = true
        })
        .catch((err) => {
          this.error = err
          // console.log(err)
        })
        .then(() => {
          this.$Message.success({ text: 'Arrangement-typen er blevet oprettet' })
          this.newCreateEventType()
          this.tabValue = 'Vis og rediger arrangement-typer'
        })

      this.eventTypeData = { eventTypeTitel: '', eventTypeDescription: '' }
    }
  }

  public newCreateEventType () : void {
    this.submitted2 = false
    this.eventTypeData = { eventTypeTitel: '', eventTypeDescription: '' }
    this.retrieveEventTypes()
    this.retrieveEvents()
  }

  public updateEvent () : void {
    console.log('Update information of event item with Id: ' + this.lastEditedEventId)
    console.log('Price: ' + !isNaN(Number(this.eventData.eventPrice)))

    if (this.eventData.eventTitle.length > 1 && this.eventData.eventDescription.length > 1 &&
      !isNaN(Number(this.eventData.eventPrice)) && this.eventData.eventStart.length > 1 &&
      this.eventData.eventEnd.length > 1 && this.eventTypeTitleValue.value !== '0') {
      const todaysDate = new Date().toISOString().split('T')[0]
      // let productId = (this.tempEvent.produkt_id === null ? 0 : this.tempEvent.produkt_id.id)
      let productId = (this.productValue.value === 0 ? 0 : this.productValue.value)
      let productTypeId = this.productTypeValue.value

      // console.log(productId)
      const promises = []
      // console.log(this.eventData)

      const asyncCreateEventProduct = async (createProduct: any) => {
        await Products.ProductsDataService.create(createProduct)
          .then((response) => {
            console.log(response.data)
            productId = response.data.id
          })
          .catch((err) => {
            this.error = err
          })
      }
      const asyncUpdateEventProduct = async (id: number, updateProductData: any) => {
        await Products.ProductsDataService.update(id.toString(), updateProductData)
          .then((response) => {
            console.log(response.data)
          })
          .catch((err) => {
            this.error = err
          })
      }

      if (productId === 0 && Number(this.eventData.eventPrice) > 0 && this.eventData.eventType !== 6) {
        console.log('Create new Product')

        productTypeId = 12
        const productData = {
          produkt_status: true,
          produkt_navn: this.eventData.eventTitle,
          produkt_pris: Number(this.eventData.eventPrice),
          produkt_datofra: todaysDate,
          produkt_datotil: (new Date(this.eventData.eventStart)).toISOString(),
          produkt_beskrivelse: 'Tilmelding til ' + this.eventData.eventTitle,
          user_id: 3, /* Fixme: should be the logged in user */
          produkt_type_id: productTypeId
        }

        promises.push(asyncCreateEventProduct(productData))
      } else if (Number(this.eventData.eventPrice) === 0 || this.eventData.eventType === 6) {
        console.log('Removing product from event')

        productId = 0
        productTypeId = 0
      } else if (productId !== 0 && productId !== null) {
        console.log('Updating product')

        const updateProductData = {
          produkt_navn: this.eventData.eventTitle,
          produkt_pris: Number(this.eventData.eventPrice),
          produkt_datotil: (new Date(this.eventData.eventStart)).toISOString(),
          produkt_beskrivelse: 'Tilmelding til ' + this.eventData.eventTitle
        }

        promises.push(asyncUpdateEventProduct(Number(productId), updateProductData))
      }

      Promise.all(promises)
        .then(() => {
          console.log(this.eventData)

          const updateEventData = {
            event_titel: this.eventData.eventTitle,
            event_beskrivelse: this.eventData.eventDescription,
            event_pris: this.eventData.eventPrice,
            event_start: (new Date(this.eventData.eventStart)).toISOString(),
            event_slut: (new Date(this.eventData.eventEnd)).toISOString(),
            event_sted: this.eventData.eventLocation,
            event_type_id: this.eventTypeTitleValue.value,
            klubber_id: this.eventClubsNameValue.value,
            user_id: 2, /* Fixme: should be the logged in user */
            event_invitation: (this.eventData.eventInvitation.length === 0 ? null : this.eventData.eventInvitation),
            event_tilmelding_mail: this.eventData.extraContactEmail,
            event_kontakt_telefon: this.eventData.extraContactTelephone,
            produkt_id: (productId === 0 ? null : productId),
            produkt_type_id: (productTypeId === 0 ? null : productTypeId),
            event_sidstetilmelding: (this.eventData.registrationDeadline === '' ? (new Date(this.eventData.eventStart)).toISOString() : this.eventData.registrationDeadline)
          }

          Events.EventsDataService.update(this.lastEditedEventId.toString(), updateEventData)
            .then((response) => {
              // this.lastUpdateEventId = response.data.id
              console.log(response.data)

              this.retrieveEvents()
              this.editEventModal = false
              this.$Message.success({ text: 'Gemt succesfuldt' })
              this.eventData = { eventInvitation: [], eventTitle: '', eventDescription: '', eventLocation: '', eventPrice: '0', eventType: 0, eventStart: '', eventEnd: '', clubEmail: '', clubTelefon: '', clubName: '', extraContactEmail: '', extraContactTelephone: '', registrationDeadline: '' }
              this.eventTypeTitleValue = { text: 'Vælg her', value: '0' }
              this.productValue = { name: 'Vælg produkt', value: 0, typeId: 0 }
              this.productTypeValue = { name: 'Vælg produktgruppe', value: 0 }
            })
            .catch((err) => {
              this.error = err
              // console.log(err)
            })
        })
        .catch((err) => {
          this.error = err
        })
    }
  }

  public updateEventType () : boolean {
    console.log('Update information of event type with Id: ' + this.lastEditedEventTypeId)

    if (this.eventTypeData.eventTypeTitel.length > 1 && this.eventTypeData.eventTypeDescription.length > 1) {
      const updateEventTypeData = {
        event_type_titel: this.eventTypeData.eventTypeTitel,
        event_type_beskrivelse: this.eventTypeData.eventTypeDescription
      }

      Events.EventTypesDataService.update(this.lastEditedEventTypeId.toString(), updateEventTypeData)
        .then((response) => {
          // this.lastUpdateEventTypeId = response.data.id
          console.log(response.data)

          this.retrieveEventTypes()
        })
        .catch((err) => {
          this.error = err
          // console.log(err)
        })

      this.editEventTypeModal = false
      this.eventTypeData = { eventTypeTitel: '', eventTypeDescription: '' }

      return true
    }

    return false
  }

  public fillEventClubsNameOptionsData () : void {
    this.eventClubsNameOptions = [{ name: 'Vælg her', value: '0' }]

    for (const clubItem of this.klubbers) {
      this.eventClubsNameOptions.push({ name: clubItem.klubber_klubnavn, value: (clubItem.id === undefined ? '0' : Number(clubItem.id).toString()) })
    }
  }

  public removeInvitation (numberInArray : number) : void {
    this.eventData.eventInvitation.splice(numberInArray, 1)
  }

  public useSelectedInvitation (flag: string) : void {
    console.log(this.selectedEventInvitation)

    if (this.selectedEventInvitation !== undefined) {
      this.eventData.eventInvitation.push(this.selectedEventInvitation)
    }

    console.log(this.eventData.eventInvitation)

    if (flag === 'edit') {
      this.addInvitationModal = false
    }
    if (flag === 'create') {
      this.addInvitationToNewEventModal = false
    }

    this.selectedEventInvitation = ''
    this.selectedFile = ''
  }

  public cancelAddInvitation (flag: string) : void {
    this.selectedEventInvitation = ''
    if (flag === 'edit') {
      this.addInvitationModal = false
    }
    if (flag === 'create') {
      this.addInvitationToNewEventModal = false
    }
    this.selectedFile = ''
  }

  public onFileSelected (event: any) : void {
    this.selectedFile = event.target.files[0]
    // console.log(this.selectedFile)
  }

  public onUpload () : void {
    if (this.selectedFile !== null && this.selectedFile.type === 'application/pdf') {
      const fd: FormData = new FormData()

      fd.append('files', this.selectedFile)

      MediasDataService.upload(fd)
        .then((response) => {
          console.log(response.data)

          alert('Invitationen er successfuldt uploadet')
          this.addInvitation()
        })
        .catch((err) => {
          this.error = err
          alert('Der gik et eller andet galt, prøv igen')
        })
    } else {
      alert('Kun pdf filer kan uploades her')
    }
  }

  public addInvitation (flag = '') : void {
    if (flag === 'edit') {
      this.addInvitationModal = true
    }
    if (flag === 'create') {
      this.addInvitationToNewEventModal = true
    }

    console.log('Fetching pdfs from API.')

    MediasDataService.getAll('updated_at:desc', null, 'ext=.pdf')
      .then((response: any) => {
        this.medias = response.data
        console.log(response.data)
      })
      .catch((err) => {
        this.error = err
      })
  }

  public retrieveProductTypeOptions () : void {
    if (this.productTypeOptions.length === 0) {
      Products.ProductTypesDataService.getAll('', null, 'produkt_type_status=true')
        .then((response) => {
          const tempProductType = response.data as SysProductProductType[]

          for (const item of tempProductType) {
            this.productTypeOptions.push({ name: item.produkt_type_navn, value: Number(item.id) })
          }
          this.productTypeOptions.sort((a, b) => a.name.localeCompare(b.name))
          this.productTypeOptions.unshift({ name: 'Vælg produktgruppe', value: 0 })
        })
        .catch((err) => {
          this.error = err
        })
    }
  }

  public retrieveClubs () : void {
    console.log('Fetching club Data from API')

    ClubsDataService.getAll('', null, 'klubber_status=true')
      .then((response) => {
        this.klubbers = response.data
        this.fillEventClubsNameOptionsData()
      })
      .catch((err) => {
        this.error = err
      })
  }

  public retrieveEventTypes () : void {
    console.log('Fetching event types data from API.')

    Events.EventTypesDataService.getAll()
      .then((response) => {
        this.eventtypes = response.data
        console.log(response.data)

        // Fill the menu type title options array
        this.eventTypeTitleOptions = [{ text: 'Vælg her', value: '0' }]
        for (const eventtype of this.eventtypes) {
          this.eventTypeTitleOptions.push({ text: eventtype.event_type_titel, value: (eventtype.id === undefined ? '0' : Number(eventtype.id).toString()) })
          // console.log('Event type titles = ' + JSON.stringify(this.eventTypeTitleOptions))
        }
        this.eventTypeTitleOptions = this.eventTypeTitleOptions.filter((element) => { return element.value !== '9' })
      })
      .catch((err) => {
        this.error = err
        // console.log(err)
      })
  }

  public retrieveEvents () : void {
    console.log('Counting Numberof Events')
    Events.EventsDataService.getCount()
      .then((response) => {
        this.totalPages = response.data
        this.totalNumberOfPages = Math.ceil(this.totalPages / this.pageSizeValue)
        console.log(this.currentPage + ' of ' + this.totalNumberOfPages + ' - total number of entries: ' + this.totalPages)
      })
      .catch((err) => {
        this.error = err
      })
    console.log('Fetching events data from API.')

    if (this.eventSearchTerm.length > 0) {
      Events.EventsDataService.findBySearchTerm((this.filterStatusStringValue === 'Udgivet'), (this.filterStatusStringValue === 'Vis alt'), this.eventSearchTerm, this.currentListSortingOrder, { slicemode: 1, start: 0, limit: -1, page: (this.currentPage - 1), pagesize: this.pageSizeValue, totalcount: this.totalPages })
        .then((response) => {
          this.events = response.data
          console.log(response.data)

          this.forceRerenderEventsList()
          this.$forceUpdate()
        })
        .catch((err) => {
          this.error = err
          // console.log(err)
        })
    } else {
      if (this.filterStatusStringValue === 'Vis alt') {
        Events.EventsDataService.getAll(this.currentListSortingOrder, { slicemode: 1, start: 0, limit: -1, page: (this.currentPage - 1), pagesize: this.pageSizeValue, totalcount: this.totalPages })
          .then((response) => {
            this.events = response.data
            console.log(response.data)

            this.forceRerenderEventsList()
            this.$forceUpdate()
          })
          .catch((err) => {
            this.error = err
            // console.log(err)
          })
      } else {
        Events.EventsDataService.findByEventStatus((this.filterStatusStringValue === 'Udgivet'), this.currentListSortingOrder, { slicemode: 1, start: 0, limit: -1, page: (this.currentPage - 1), pagesize: this.pageSizeValue, totalcount: this.totalPages })
          .then((response) => {
            this.events = response.data
            console.log(response.data)

            this.forceRerenderEventsList()
            this.$forceUpdate()
          })
          .catch((err) => {
            this.error = err
            // console.log(err)
          })
      }
    }
  }

  async mounted () : Promise<void> {
    const loggedInStatus = localStorage.getItem('status')
    const loginType = localStorage.getItem('logintype')
    const apiToken = localStorage.getItem('apitoken')
    const userdata = localStorage.getItem('user')

    if (loggedInStatus !== undefined && loggedInStatus !== null && loggedInStatus === true.toString() && loginType !== undefined && loginType !== null && loginType === true.toString() && apiToken !== undefined && apiToken !== null && apiToken.length >= 100 && userdata !== undefined && userdata !== null && !userdata.startsWith('{"id":1,')) {
      const userString = localStorage.getItem('user')?.toString()
      const userDataObject = (userString !== undefined && userString !== null ? JSON.parse(userString) : null)

      if (userDataObject !== null && userDataObject.id !== 1 && userDataObject.id < 100 && userDataObject.role !== undefined && userDataObject.role !== null && userDataObject.role.id === 5) {
        this.retrieveEventTypes()
        this.retrieveEvents()
        this.retrieveClubs()
        this.retrieveProductTypeOptions()
      } else {
        CommonFunctions.redirectLogin()
      }
    } else {
      CommonFunctions.redirectLogin()
    }
  }
}

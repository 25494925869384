import http from '../http-common'
import { APIBaseFunctions, APIDataSliceObj, defaultAPILimit } from '@/components/Utility/APIBase'

class MediasDataService extends APIBaseFunctions {
  private cachedMediasDataSliceObj: APIDataSliceObj = {
    slicemode: 0,
    start: 0,
    limit: defaultAPILimit,
    page: 0,
    pagesize: 0,
    totalcount: 0
  }

  getAll (sortingOrder = '', dataSliceObj: APIDataSliceObj | null = null, extraParameter = '') {
    const currentDataSliceObj = MediasDataService.processDataSliceObj({ cachedServicesDataSliceObj: this.cachedMediasDataSliceObj }, dataSliceObj)

    if (sortingOrder.length > 0) {
      return http.get(`/upload/files?_sort=${sortingOrder}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
    }

    return http.get(`/upload/files?_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
  }

  get (id: string) {
    return http.get(`/upload/files/${id}`)
  }

  getCount (extraCountParameter = '') {
    return http.get('/upload/files/count' + (extraCountParameter.length > 0 ? `?${extraCountParameter}` : ''))
  }

  upload (data: any) {
    return http.post('/upload', data)
  }

  // Fixme: Functions that do HTTP POST requests should be named create and not update. Function name update is only to be used for HTTP PUT requests.
  update (id: string, data: any) {
    return http.post(`/upload?id=${Number(id)}`, data)
    // return http.put(`/upload/files/${id}`, data)
  }

  delete (id: string) {
    return http.delete(`/upload/files/${id}`)
  }

  deleteAll () {
    return http.delete('/upload/files')
  }

  findBySearchTerm (searchTerm: string, sortingOrder = '', dataSliceObj: APIDataSliceObj | null = null, extraParameter = '') {
    let sortingOrderParameter = ''
    const currentDataSliceObj = MediasDataService.processDataSliceObj({ cachedServicesDataSliceObj: this.cachedMediasDataSliceObj }, dataSliceObj)
    searchTerm = searchTerm.trim()

    if (sortingOrder.length > 0) {
      sortingOrderParameter = `&_sort=${sortingOrder}`
    }

    // Simple search of file names
    return http.get(`/upload/files?name_contains=${searchTerm}${sortingOrderParameter}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
  }
}

export default new MediasDataService()
